import './Home.css';

export default function Home() {
    return (
        <div className="Home">
            <img src="logo.png" className="Home-logo" alt="logo" />
            <div style={{height: "8vh"}} />
            <div className="Home-content">
                {/* <h1>Build intelligent robots</h1> */}
                <h1>Solve robot decision making</h1>

                <p>We use multi-agent simulation to</p>
                <ul>
                    <li>test and fail fast</li>
                    <li>generate unlimited targeted data</li>
                </ul>

                <p>What many disagree with, and we are betting on:</p>
                <ul>
                    <li>Most robot limitations can be shown in sim</li>
                    <li>Most training data for embodied decision making will be simulated</li>
                </ul>

                {/* <button className="contact-button">hello@sim2sim.tech</button> */}
            </div>
        </div>
    );
}